import axios from 'axios';
import router from '@/router';

axios.defaults.timeout = 50000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {

  return config;
}, error => {
  return Promise.error(error);
});
axios.interceptors.response.use(response => {

  let date = response.data;
  if (date.code === 999) {
    sessionStorage.setItem('user',null);
    router.push('/login');
  }
  return date;
}, error => {
  return Promise.reject(error);
});

export default axios;
