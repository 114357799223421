<template>
  <div class="common-layout">

    <van-nav-bar title="提醒事项" >

    </van-nav-bar>

    <van-cell-group inset>
      <van-cell title="名字" :value="remindItem.name"  />
      <van-cell title="提醒时间" :value="remindItem.remindTime" />
      <van-cell title="提醒类型" :value="remindItem.remindType" />
      <van-cell title="状态" :value="remindItem.state" />
    </van-cell-group>
    <van-button type="primary" @click="close" > 确认</van-button>

  </div>
</template>
<script>
import {getRemind, closeRemind} from '@/requests/remindItem';
import {Toast} from 'vant';

export default {
  'name': 'RemindView',
  data() {
    return {
      'msg': '',
      'remindItem': {},
      'id': '',
      'uuid': ''
    };
  }, 'methods': {
    close() {
      closeRemind(this.id, this.uuid).then(res => {
        if (res.code === 200) {
          Toast.success('关闭提醒事项成功');
          this.get();
        } else {
          Toast.fail(res.message);
        }
      });
    }, get() {
      this.msg = '正在获取提醒事项...';
      getRemind(this.id).then(res => {
        if (res.code === 200) {
          this.remindItem = res.data;
          this.msg = '获取提醒事项成功';
        } else {
          this.msg = res.message;
        }
      });
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.uuid = this.$route.query.uuid;
    this.get();
  }
};
</script>

<style scoped lang="scss">
.common-layout {
  height: 100%;
  background-color: #f1f0f0;
  padding: 0 0 0 0;
  .van-cell-group{
    margin-top: 30%;
    margin-bottom: 30px;
  }
  .van-button{
    width: 60%;
  }
}
</style>
