<template>
  <div class="common-layout">

    <van-nav-bar title="账单信息" left-arrow @click-left="onClickLeft">
    </van-nav-bar>

    <van-cell-group inset>
      <van-cell :title="billInfo.commodity"/>
      <van-cell title="交易平台" :value="billInfo.platform"/>
      <van-cell title="交易类型" :value="billInfo.type"/>
      <van-cell title="支付方式" :value="billInfo.payWay"/>
      <van-cell title="交易对手" :value="billInfo.counterparty"/>
      <van-cell :title="billInfo.inOrOut" :value="formatAmt()"/>
      <van-cell title="交易状态" :value="billInfo.state"/>
      <van-cell title="交易时间" :value="billInfo.tradeTime"/>
    </van-cell-group>

  </div>
</template>
<script>


export default {
  'name': 'BillInfoView',
  data() {
    return {
      'msg': '',
      'billInfo': {},
      'id': '',
      'uuid': ''
    };
  },
  'methods': {
    formatAmt() {
      if (this.billInfo.amt){
        return this.billInfo.amt.toFixed(2);
      }
      return '0.00';
    },
    onClickLeft() {
      history.back();
    }
  },
  mounted() {
    let billInfo = this.$route.params.billInfo;
    if (!billInfo) {
      this.$router.push('/billAnalysis');
      return;
    }
    this.billInfo = JSON.parse(billInfo);
  }
};
</script>

<style scoped lang="scss">
.common-layout {
  height: 100%;
  background-color: #f1f0f0;
  padding: 0 0 0 0;

  .van-cell-group {
    margin-top: 20%;
    margin-bottom: 30px;
  }

  .van-button {
    width: 60%;
  }
}
</style>
