<template>
  <div ref="dailyBox" class="daily-box"></div>
</template>

<script>
import echartsBarPng from '@/assets/echarts-bar.png';
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {BarChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';
import {Toast} from 'vant';
import {dailyBill} from '@/requests/billAnalysis';
import {markRaw} from 'vue';


// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  DataZoomComponent
]);
export default {
  'name': 'DailyBillComponent',
  'emits': ['onClickEcharts'],
  data() {
    return {
      'option': {
        'legend': {
          'data': ['微信-收入', '微信-支出', '支付宝-收入', '支付宝-支出'],
          'left': '10'
        },

        'toolbox': {
          'feature': {
            'dataView': {},
            'saveAsImage': {},
            'myChange': {
              'show': true,
              'title': '转换',
              //'icon': 'image://https://echarts.apache.org/zh/images/favicon.png',
              'onclick': function () {

              }
            }
          }
        },
        'tooltip': {},
        'xAxis': {
          'data': ['class0', 'class1', 'class2', 'class3', 'class4', 'class5', 'class6', 'class7', 'class8', 'class9'],
          'name': '日'

        },
        'yAxis': {
          'name': '元'

        },
        'grid': {
          'left': 50,
          'right': 20,
          'top': 80,
          'bottom': 100
        },
        'series': [
          {
            'name': '微信-收入',
            'type': 'bar',
            'stack': 'all',
            'itemStyle': {
              'color': 'rgba(23,217,38,0.96)'
            },
            'emphasis': {
              'itemStyle': {
                'shadowBlur': 10,
                'shadowColor': 'rgba(0,0,0,0.3)'
              }
            },
            'data': [1, 2, 3, 4, 5, 6, 7, 8, 9, 410]
          },
          {
            'name': '微信-支出',
            'type': 'bar',
            'stack': 'all',
            'itemStyle': {
              'color': 'rgba(13,173,26,0.96)'
            },
            'emphasis': {
              'itemStyle': {
                'shadowBlur': 10,
                'shadowColor': 'rgba(0,0,0,0.3)'
              }
            },
            'data': [1, 2, 3, 44, 5, 6, 7, 8, 9, 10]
          },
          {
            'name': '支付宝-收入',
            'type': 'bar',
            'stack': 'all',
            'itemStyle': {
              'color': 'rgba(89,133,224,0.96)'
            },
            'emphasis': {
              'itemStyle': {
                'shadowBlur': 10,
                'shadowColor': 'rgba(0,0,0,0.3)'
              }
            },
            'data': [1, 2, 3, -44, 5, 6, -74, 8, 9, -110]
          },
          {
            'name': '支付宝-支出',
            'type': 'bar',
            'stack': 'all',
            'itemStyle': {
              'color': 'rgba(70,89,182,0.96)'
            },
            'emphasis': {
              'itemStyle': {
                'shadowBlur': 10,
                'shadowColor': 'rgba(0,0,0,0.3)'
              }
            },
            'data': [1, -2, -3, -4, 0, 6, -7, 8, 9, -210]
          }
        ],
        'dataZoom': [
          {
            'type': 'slider',
            'show': true,
            'start': 94,
            'end': 100,
            'handleSize': 8
          },
          {
            'type': 'inside',
            'start': 94,
            'end': 100
          }

        ]
      },
      'lastDate': '',
      'dataZoomSize': 30,
      'myChart': null,
      'myChartType': 1,//4 2 1
      'xDate': [],
      'wxIncome': [],
      'wxPay': [],
      'aliIncome': [],
      'aliPay': [],
      'income': [],
      'pay': [],
      'total': [],
      'onload':false
    };
  },
  mounted() {
    let dailyBox = this.$refs.dailyBox;
    // 基于准备好的dom，初始化echarts实例
    this.myChart = markRaw(echarts.init(dailyBox));
    this.loadData();
  },
  'methods': {
    loadData() {
      if (this.onload) {
        return;
      }
      this.onload=true;
      this.xDate=[];
      this.wxIncome=[];
      this.wxPay=[];
      this.aliIncome=[];
      this.aliPay=[];
      this.pay=[];
      this.income=[];
      this.total=[];
      dailyBill().then(res => {
        if (res.code == 200) {
          for (const item of res.data) {
            this.xDate.push(item.date);
            this.lastDate = item.date;
            this.wxIncome.push(item.wxIncome.toFixed(2));
            this.wxPay.push((-item.wxPay).toFixed(2));
            this.aliIncome.push(item.aliIncome.toFixed(2));
            this.aliPay.push((-item.aliPay).toFixed(2));
            this.pay.push((-item.aliPay - item.wxPay).toFixed(2));
            this.income.push((item.wxIncome + item.aliIncome).toFixed(2));
            this.total.push((item.wxIncome + item.aliIncome - item.wxPay - item.aliPay).toFixed(2));
          }

          this.reloadChart();
          this.onClickMyChart({'name': this.lastDate, 'seriesName': '合计'});
        } else if (res.code==201) {
          this.$router.push('/billFile');
          Toast.fail(res.message);
        }else {
          Toast.fail(res.message);
        }
        this.onload=false;
      });
    },
    onClickMyChart(params) {
      let date = params.name,
        billType = params.seriesName;

      this.$emit('onClickEcharts', {'date': date, 'billType': billType, 'type': 'daily'});
    },
    reloadChart() {
      this.setToolBoxOption();
      this.setDataZoomOption();
      this.setDateOption();
      if (this.myChartType == 4) {
        this.setSeries4Option();
      } else if (this.myChartType == 2) {
        this.setSeries2Option();
      } else {
        this.setSeries1Option();
      }
      // 绘制图表
      this.myChart.setOption(this.option, {'notMerge': true});
      this.myChart.on('click', this.onClickMyChart);

    },
    setToolBoxOption() {
      this.option.toolbox.feature.myChange = {
        'show': true,
        'title': '转换',
        'icon': 'image://' + echartsBarPng,
        'onclick': () => {
          this.myChartType = this.myChartType == 4 ? 2 : this.myChartType == 2 ? 1 : 4;
          this.reloadChart();
        }
      };

    },
    setDataZoomOption() {
      let length = this.xDate.length,
        start = (length - this.dataZoomSize) / length;
      start = start < 0 ? 0 : start * 100;

      this.option.dataZoom = [
        {
          'type': 'slider',
          'show': true,
          'start': start,
          'end': 100,
          'handleSize': 8
        },
        {
          'type': 'inside',
          'start': start,
          'end': 100
        }
      ];

    },
    setDateOption() {
      this.option.xAxis = {
        'data': this.xDate,
        'name': '日'
      };
    },
    setSeries4Option() {
      this.option.legend = {
        'data': ['微信-收入', '微信-支出', '支付宝-收入', '支付宝-支出'],
        'left': '10%', 'width': '70%'
      };
      this.option.series = [
        {
          'name': '微信-收入',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(23,217,38,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.wxIncome
        },
        {
          'name': '微信-支出',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(13,173,26,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.wxPay
        },
        {
          'name': '支付宝-收入',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(89,133,224,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.aliIncome
        },
        {
          'name': '支付宝-支出',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(70,89,182,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.aliPay
        }
      ];

    },
    setSeries2Option() {
      this.option.legend = {
        'data': ['收入', '支出'],
        'left': '10%'
      };
      this.option.series = [
        {
          'name': '收入',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(23,217,38,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.income
        },
        {
          'name': '支出',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(210,31,31,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.pay
        }
      ];
    },
    setSeries1Option() {
      this.option.legend = {
        'data': ['合计'],
        'left': '10%'
      };
      this.option.series = [
        {
          'name': '合计',
          'type': 'bar',
          'stack': 'all',
          'itemStyle': {
            'color': 'rgba(210,31,31,0.96)'
          },
          'emphasis': {
            'itemStyle': {
              'shadowBlur': 10,
              'shadowColor': 'rgba(0,0,0,0.3)'
            }
          },
          'data': this.total
        }

      ];
    }
  }
};
</script>

<style scoped lang="scss">

.daily-box {
  height: 300px;

}

</style>
