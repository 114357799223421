<template>
  <div style="padding-top: 70%;text-align: center;background-color: #f1f0f0;height: 100%;box-sizing: border-box">
    <div style="margin-bottom: 30px">
      {{ message }}
    </div>
    <van-cell-group  style="margin-bottom: 15px" inset>
      <van-field v-model="password" center label="密码" placeholder="请输入密码" >
        <template #button>
          <van-button size="small"  type="primary" :disabled="disabled" @click="onStart">开 始 点 餐</van-button>
        </template>
      </van-field>
    </van-cell-group>

  </div>
</template>

<script>
import {start} from '@/requests/canteen';

export default {
  'name': 'OrderStartView',
  data() {
    return {
      'flag': false,
      'message': '准备点餐',
      'password':'',
      'disabled':false
    };
  },
  mounted() {

  }, 'methods': {
    onStart() {
      if (!this.password){
        this.message='请输入密码';
        return;
      }

      start({'password':this.password}).then(res => {
        if (res.code == 200) {
          this.message = '点餐程序已启动';
          this.disabled=true;
        } else {
          this.message = res.message;
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
