<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="刮刮乐"
                   left-text="返回"
                   left-arrow
                   @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>

    <div style="height: 10px"></div>
    <div>
      <van-form @submit="save">
        <van-cell-group inset>
          <van-field name="类型" label="类型" v-model="type" readonly @click="showType" placeholder="请选择类型"
                     :rules="[{ required: true, message: '请选择类型' }]"
          ></van-field>
          <van-field name="编号" label="编号" v-model="code" placeholder="请填写编号"
                     :rules="[{ required: true, message: '请填写编号' }]"
          ></van-field>
          <van-field name="票价" label="票价" v-model="ticketPrice" placeholder="请填写票价"
                     type="number"
                     :rules="[{ required: true, message: '请填写票价' }]"
          ></van-field>
          <van-field name="奖金" label="奖金" v-model="bonus" placeholder="请填写奖金"
                     type="number"
                     :rules="[{ required: true, message: '请填写奖金' }]"
          ></van-field>
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>


    <van-popup v-model:show="show" position="bottom">
      <van-picker
          title="类型"
          v-model="type"
          :columns="types"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
      />
    </van-popup>

    <van-row>
      <van-col span="24">
        <van-divider class="upload-title" content-position="left">列表</van-divider>
      </van-col>
    </van-row>

    <van-list>
      <van-swipe-cell style="margin: 2px 0" v-for="(item,index) in guagualeList" :key="index">

        <van-cell :value="item.rate" center :label="item.code">
          <template #title>
            <span class="custom-title">{{ item.code }} </span>
          </template>
          <template #label>
            <span class="custom-title">【{{ item.type }}】奖金：{{item.bonus}}，票价：{{item.ticketPrice}} </span>
          </template>
          <template #value>
            <span class="custom-title">回报率：{{item.rate}} </span>
          </template>
        </van-cell>

        <template #right>
          <van-button square type="danger" class="cell-btn" text="删除" @click="del(item)"/>
        </template>
      </van-swipe-cell>

    </van-list>

  </div>

</template>

<script>


import {Toast} from 'vant';
import {guagualeDel, guagualeList, guagualeSave} from '@/requests/guaguale';

export default {
  'name': 'GuaGuaLeView',
  data() {
    return {
      'show': false,
      'types': ['福彩', '体彩'],
      'type': '福彩',
      'code': '',
      'ticketPrice':'',
      'bonus': '',
      'guagualeList': []
    };
  },
  mounted() {
    this.reload();
  },
  'methods': {
    reload() {
      guagualeList().then(res => {
        if (res.code == 200) {
          this.guagualeList = res.data;
        } else {
          Toast.fail(res.message);
        }
      });
    },
    del(item) {
      guagualeDel(item.id).then(res => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.reload();
        } else {
          Toast.fail(res.message);
        }
      });
    },
    save() {
      /*检查code中是否有O*/
      if (this.code.indexOf('O')!=-1){
        this.code=this.code.replaceAll('O','0');
        Toast.fail('重新检查编号');
        return;
      }
      guagualeSave(this.type, this.code,this.ticketPrice, this.bonus).then(res => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.reload();
          this.code = '';
        } else {
          Toast.fail(res.message);
        }
      });
    }, onConfirm() {
      this.show = false;
    }, onChange(val) {
      this.type = val;
    }, onCancel() {
      this.show = false;
    }, showType() {
      this.show = true;
    }, onClickLeft() {
      this.$router.push('/main');
    }

  },
  'computed': {}
}
;
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

}

.dates-title {
  font-weight: bold;
  font-size: 16px;
  color: #1989fa;
  border-color: #1989fa;
}

.upload-title {
  font-weight: bold;
  font-size: 16px;
  color: rgba(28, 46, 59, 0.88);
  border-color: rgba(28, 46, 59, 0.88);
  padding: 0 16px;

}

.dates-value {

  text-align: left;
  color: rgba(27, 41, 47, 0.90);
  font-weight: 200;
  font-size: 16px;
}

.cell {
  min-height: 70px;
}

.cell-btn {
  height: 100%;
}
</style>
