import axios from '@/requests';

function guagualeList() {
  return axios.post('/guaguale/list');
}

function guagualeSave(type, code, ticketPrice, bonus) {
  return axios.post(`/guaguale/save/${type}/${code}/${ticketPrice}/${bonus}`);
}

function guagualeDel(id) {
  return axios.post(`/guaguale/del/${id}`);
}

export {
  guagualeList, guagualeSave, guagualeDel
};
