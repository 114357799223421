import axios from '@/requests';


function savePrivateInfo(data) {
  return axios.post('/privateInfo/save',data);
}


export {
  savePrivateInfo
};
