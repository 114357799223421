import {createRouter, createWebHashHistory} from 'vue-router';

import LoginView from '@/view/LoginView';
import RemindView from '@/view/remind/RemindView';
import MainView from '@/view/MainView';
//import store from '@/store';
import RemindListView from '@/view/remind/RemindListView';
import RemindAddView from '@/view/remind/RemindAddView';
import RemindEditView from '@/view/remind/RemindEditView';
import BillFileView from '@/view/bill/BillFileView';
import BillAnalysisView from '@/view/bill/BillAnalysisView';
import BillInfoView from '@/view/bill/BillInfoView';
import LampCalculateView from '@/view/lamp/LampCalculateView';
import PrivateInfoView from '@/view/info/PrivateInfoView';
import OrderStartView from '@/view/canteen/OrderStartView';
import ETFMedianView from '@/view/etf/ETFMedianView';
import DouYinVideoView from '@/view/free/DouYinVideoView';
import HotPicView from '@/view/free/HotPicView';
import HotVideoView from '@/view/free/HotVideoView';
import IndexView from '@/view/IndexView';
import GuaGuaLeView from '@/view/guaguale/GuaGuaLeView';
import LaJiView from '@/view/free/LaJiView';
import TorrentView from '@/view/torrent/TorrentView';


const router = createRouter({

  'history': createWebHashHistory(),
  'routes': [
    {
      'path': '/',
      'component': IndexView
    },
    {
      'name': 'login',
      'path': '/login',
      'component': LoginView
    }, {
      'name': 'main',
      'path': '/main',
      'component': MainView
    },
    {
      'name': 'remindList',
      'path': '/remindList',
      'component': RemindListView

    },
    {
      'name': 'remindAdd',
      'path': '/remindAdd',
      'component': RemindAddView
    },
    {
      'name': 'remindEdit',
      'path': '/remindEdit',
      'component': RemindEditView
    },
    {
      'path': '/remind',
      'component': RemindView
    }, {
      'name': 'billFile',
      'path': '/billFile',
      'component': BillFileView
    }, {
      'name': 'billAnalysis',
      'path': '/billAnalysis',
      'component': BillAnalysisView,
      'meta': {
        'keepAlive': true
      }
    }, {
      'name': 'billInfo',
      'path': '/billInfo',
      'component': BillInfoView
    }, {
      'name': 'lampCalculate',
      'path': '/lampCalculate',
      'component': LampCalculateView
    }, {
      'name': 'privateInfo',
      'path': '/privateInfo',
      'component': PrivateInfoView
    }, {
      'name': 'canteenOrder',
      'path': '/canteenOrder',
      'component': OrderStartView
    }, {
      'name': 'etfMedian',
      'path': '/etfMedian',
      'component': ETFMedianView
    }, {
      'name': 'douyinVideo',
      'path': '/douyinVideo',
      'component': DouYinVideoView
    }, {
      'name': 'hotPic',
      'path': '/hotPic',
      'component': HotPicView
    }, {
      'name': 'hotVideo',
      'path': '/hotVideo',
      'component': HotVideoView
    },{
      'name': 'guaguale',
      'path': '/guaguale',
      'component': GuaGuaLeView
    },{
      'name': 'laji',
      'path': '/laji',
      'component': LaJiView
    },{
      'name':'torrent',
      'path':'/torrent',
      'component':TorrentView
    }
  ]
});
/**
 * @desc 全局监听路由变化
 */
/*router.beforeEach((to, from, next) => {
  if (to.path === '/main') {
    if (!store.state.user) {
      next('/login');
    }else {
      next();
    }
  } else {
    next();
  }
});*/
// 5.导出路由
export default router;
