<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="种子转磁力"
                   left-text="返回"
                   left-arrow
                   @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>


    <van-list class="list-view">
      <van-swipe-cell class="swipe-cell" v-for="(item,index) in torrentList" :key="index">

        <van-cell  class="cell" :label="item.md5"
                  :value="item.magnet" @click="copyMagnet(item.magnet)">
          <template #title>
            <span style="word-break: break-word">{{ item.name }}</span>
          </template>
        </van-cell>

        <!--        <template #right>
                  <van-button square type="danger" class="cell-btn" text="删除" @click="del(item)"/>
                  <van-button square type="primary" class="cell-btn" text="导入" @click="read(item)"/>
                </template>-->
      </van-swipe-cell>

    </van-list>
    <!--    <van-row>
          <van-col span="24">
            <van-divider class="dates-title" content-position="left">上传</van-divider>
          </van-col>
        </van-row>-->


    <van-row class="upload-view">
      <van-col span="2"/>
      <van-col span="20">
        <van-uploader style="width: 100%" :after-read="afterRead" accept=".torrent">
          <van-button style="width: 100%" icon="plus" type="primary">上传种子</van-button>
        </van-uploader>
      </van-col>
      <van-col span="2"/>
    </van-row>

  </div>
</template>

<script>
import {torrentList, torrentToMagnet} from '@/requests/torrent';
import {Toast} from 'vant';

export default {
  'name': 'TorrentView',
  data() {
    return {
      'torrentList': []
    };
  },
  mounted() {
    this.reload();
  },
  'methods': {
    onClickLeft() {
      this.$router.go(-1);
    },
    reload() {
      torrentList().then(res => {
        if (res.code == 200) {
          this.torrentList = res.data;
        } else {
          Toast.fail(res.message);
        }
      });
    },
    afterRead(info) {
      torrentToMagnet(info.file).then(res => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.reload();
        } else {
          Toast.fail(res.message);
        }
      });
    }, copyMagnet(magnet) {
      navigator.clipboard.writeText(magnet).then(
        function () {
          /* clipboard successfully set */
          Toast.success('复制成功');
        },
        function () {
          /* clipboard write failed */
          Toast.success('复制失败');
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.main-box {
  display: flex;
  flex-direction: column;
  height: 100%;

  .list-view {
    overflow: scroll;
    background-color: #f1f0f0;
    flex: 1;
    .swipe-cell{
      margin: 1px 0;
    }
    .swipe-cell:first-child{
      margin: 0;
    }

  }

  .upload-view {
    margin: 21px 0;
    /deep/ .van-uploader__input-wrapper{
      width: 100%;
    }
  }
}
</style>
