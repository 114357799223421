import axios from '@/requests';

function douyin() {
  return axios.post('/free/douyin');
}

function pic() {
  return axios.post('/free/pic');
}

function hotVideo() {
  return axios.post('/free/hotVideo');
}

function watchVideo(videoId) {
  return axios.post(`/free/hotVideo/watch/${videoId}/1`);
}

function laji(name) {
  return axios.post(`/free/laji/${name}`);
}

function lajiHis() {
  return axios.post('/free/laji/his');
}

export {
  douyin, pic, hotVideo, watchVideo, laji, lajiHis
};
