<template>
  <div>
    <van-nav-bar title="灯具照明范围计算">

    </van-nav-bar>

    <van-row>
      <van-col span="2"></van-col>
      <van-col span="22" style="text-align: left;">
        <p>地面照明直径: {{ diameter }}cm</p>
        <p>桌面照明直径: {{ tableDiameter }}cm</p>
      </van-col>
    </van-row>

    <van-form>
      <van-cell-group inset>
        <van-field
            v-model="height"
            name="层高"
            type="number"
            label="层高（cm）"
            placeholder="请输入层高（cm）"
            :rules="[{ required: true, message: '请填写层高（cm）' }]"
        />
        <van-field
            v-model="angle"
            name="光束角"
            type="number"
            label="光束角（度）"
            placeholder="请输入光束角（度）"
            :rules="[{ required: true, message: '请填写光束角（度）' }]"
        />
        <van-field
            v-model="tableHeight"
            name="桌面高度"
            type="number"
            label="桌面高度（cm）"
            placeholder="请输入桌面高度（cm）"
            :rules="[{ required: true, message: '请填写桌面高度（cm）' }]"
        />

      </van-cell-group>


      <!--      <div style="margin: 16px;">
              <van-button round block type="primary" native-type="submit">
                提交
              </van-button>
            </div>-->
    </van-form>
  </div>
</template>

<script>


export default {
  'name': 'LampCalculateView',
  'components': {},
  data() {
    return {
      'height': 230,
      'angle': 58,
      'tableHeight': 80
    };
  }, 'methods': {},
  'computed': {
    diameter(){
      if (!this.height||!this.angle) {
        return 0;
      }
      let r = this.height * Math.tan(this.angle / 2 / 180 * Math.PI);
      return 2*r;
    },
    tableDiameter(){
      if(!this.height||!this.angle||!this.tableHeight){
        return 0;
      }
      let r = (this.height-this.tableHeight) * Math.tan(this.angle / 2 / 180 * Math.PI);
      return 2*r;
    }
  }
};
</script>

<style scoped>

</style>
