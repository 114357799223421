<template>
  <div class="img-div">
    <van-swipe style="width: 100%;height: 100%" lazy-render vertical>
      <van-swipe-item v-for="(item,index ) in imageUrl" :key="index">
        <img :src="getPicUrl(item)">
      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
import {pic} from '@/requests/free';

export default {
  'name': 'HotPicView',
  data() {
    return {
      'imageUrl': []
    };
  }, mounted() {
    pic().then(res => {
      if (res.code == 200) {
        this.imageUrl = res.data;
      }
    });
  }, 'methods': {
    getPicUrl(url) {
      return 'https://tools.zhangjianyong.top/api/free/pic?url=' + url;
    }
  }
};
</script>

<style scoped lang="scss">
.img-div {
  width: 100%;
  height: 50%;
  margin: auto;
  background-color: black;

  img {
    max-height: 100%;
    max-width: 100%;

  }
}
</style>
