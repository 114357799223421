<template>
  <div class="common-layout">
    <van-nav-bar title="首页" />
    <van-grid class="grid" :gutter="10" :clickable="true" square :column-num="2">
      <van-grid-item icon="setting" text="管理页面" to="/login" />
     <van-grid-item icon="hot" text="热门视频" to="/hotVideo"/>
      <van-grid-item icon="play-circle" text="抖音" to="/douyinVideo"/>
      <van-grid-item icon="gold-coin" text="etf" to="/etfMedian"/>
      <van-grid-item icon="thumb-circle" text="点餐程序" to="/canteenOrder"/>
      <van-grid-item icon="delete" text="垃圾分类" to="/laji"/>
      <van-grid-item icon="font" text="种子转磁力" to="/torrent"/>

    </van-grid>
    <div class="bottom-box">
      <a class="bottom-text" href="https://beian.miit.gov.cn" >浙ICP备20014065号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  'name': 'IndexView',
  data() {
    return {
    };
  }, mounted() {

  }, 'methods': {}, 'computed': {}
};
</script>

<style scoped lang="scss">

.common-layout {
  height: 100%;
  width: 100%;

  .grid{
    margin-top: 10px;
  }
}

.bottom-box{
  position: fixed;
  bottom: 5px;
  width: 100%;
  .bottom-text{
    color: #bbb;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    line-height: 39px;
  }
}

</style>
