<template>
  <div class="common-layout">
    <div class="header">
      <h1>tools</h1>
    </div>
    <div class="main">
      <van-form @submit="login">
        <van-cell-group inset>
          <van-field
              v-model="username"
              name="用户名"
              label="用户名"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
              v-model="password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
              :rules="[{ required: true, message: '请填写密码' }]"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="bottom-box">
      <a class="bottom-text" href="https://beian.miit.gov.cn" >浙ICP备20014065号-1</a>
    </div>
  </div>
</template>

<script>
import {login} from '@/requests/remindItem';
import {Toast} from 'vant';


export default {
  'name': 'LoginView',
  data() {
    return {
      'username': '',
      'password': ''
    };
  }, mounted() {

    this.username = this.$route.query.username;
    this.password = this.$route.query.password;
    if (this.username && this.password) {
      if (this.username.length > 0 && this.password.length > 0) {
        this.login();
        return;
      }
    }
    if (this.user){
      this.$router.push('/main');
      return;
    }
  }, 'methods': {
    login() {
      login({'username': this.username, 'password': this.password})
        .then(res => {
          if (res.code === 200) {
            Toast.success('登陆成功');

            //this.$store.commit('setUser', res.data);
            this.user = res.data;
            this.$router.push('/main');
          } else {
            Toast.fail(res.message);
          }
        });
    }
  }, 'computed': {
    'user': {
      get() {
        //return this.$store.state.user;
        return JSON.parse(sessionStorage.getItem('user'));
      },
      set(val) {
        //this.$store.commit('setUser', val);
        sessionStorage.setItem('user',JSON.stringify(val));
      }
    }
  }

};
</script>

<style scoped lang="scss">

.common-layout {
  height: 100%;
}

.header {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 20px;
}

.main {
  margin-top: 50px;
  padding: 20px;
  height: 50%;
}

.bottom-box{
  position: fixed;
  bottom: 5px;
  width: 100%;
  .bottom-text{
    color: #bbb;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    line-height: 39px;
  }
}

</style>
