<template>
  <div class="main-box">
    <van-sticky>
      <van-nav-bar title="对账文件"
                   left-text="返回"
                   right-text="刷新"
                   left-arrow
                   @click-right="onClickRight"
                   @click-left="onClickLeft">

      </van-nav-bar>


      <van-tabs v-model:active="active" @change="onChangeTabs" class="top-item" shrink swipeable>
        <van-tab name="日账单" title="日账单">
          <DailyBillComponent ref="dailyBill" @onClickEcharts="onClickEcharts"></DailyBillComponent>
        </van-tab>
        <van-tab name="月账单" title="月账单">
          <MonthBillComponent ref="monthBill" @onClickEcharts="onClickEcharts"></MonthBillComponent>
        </van-tab>
      </van-tabs>
      <van-row class="top-item">
        <van-col span="24">
          <van-divider class="dates-title" content-position="left">{{ showDate }}</van-divider>
        </van-col>
      </van-row>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model:loading="loading" :finished="finished"
                finished-text="没有更多了" @load="onLoad">
        <van-cell v-for="(item,index ) in tableData" :key="index"
                  :label="item.tradeTime"
                  :value="formatAmt(item)"
                  is-link
                  :to="{'name':'billInfo','params':{'billInfo':JSON.stringify(item)}}">
          <template #title>
            <span style="word-break: break-all">{{ item.commodity }}</span>
          </template>

        </van-cell>
      </van-list>
    </van-pull-refresh>


  </div>

</template>

<script>

import DailyBillComponent from '@/component/bill/DailyBillComponent';
import MonthBillComponent from '@/component/bill/MonthBillComponent';
import {rank} from '@/requests/billAnalysis';
import {Toast} from 'vant';

export default {
  'name': 'BillAnalysisView',
  data() {
    return {
      'refreshing': false,
      'loading': false,
      'finished': true,
      'active': '日账单',
      'dailyParams': {},
      'monthParams': {},
      'currentPage': 1,
      'pageSize': 20,
      'totalPage': 10,
      'tableData': []
    };
  },
  'components': {
    MonthBillComponent,
    DailyBillComponent
  },
  mounted() {
  },
  'methods': {
    formatAmt(bill) {
      return bill.inOrOut + '：' + bill.amt.toFixed(2);
    },
    onLoad() {
      this.currentPage = this.currentPage + 1;
      this.getTableData(1);
    },
    onChangeTabs() {
      this.onRefresh();
    },
    onRefresh() {
      this.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.getTableData(0);
    },
    //列表结果处理
    /*0-刷新，1-加载，2-修改*/
    pushData(data, type) {
      //修改数据
      if (type == 0) {
        this.tableData = data.records;
      } else if (type == 1) {
        this.tableData.push(...data.records);
      } else if (type == 2) {
        let start = (data.current - 1) * data.size,
          resData = [];
        for (let i = 0; i < start; i++) {
          resData.push(this.tableData[i]);
        }
        resData.push(...data.records);
        this.tableData = resData;
      }

      //修改标记
      if (this.currentPage > data.pages) {
        this.currentPage = data.pages;
      }
      //this.currentPage = data.current;
      this.totalPage = data.pages;

      if (data.current >= this.totalPage) {
        this.finished = true;
      }

      this.loading = false;
      this.refreshing = false;


    },
    /*0-刷新，1-加载，2-修改*/
    getTableData(type) {
      console.log(type);
      //请求数据
      rank(this.currentParams.type, this.currentParams.date, this.currentParams.platform,
        this.currentParams.inOrOut, this.currentPage, this.pageSize).then(res => {
        if (res.code == 200) {
          let data = res.data;
          this.pushData(data, type);

        } else {
          Toast.fail(res.message);
        }
      });
    },
    onClickRight() {
      this.$refs.dailyBill.loadData();
      this.$refs.monthBill.loadData();
    },
    onClickLeft() {
      this.$router.push('/main');
    },
    onClickEcharts(params) {
      //确定参数
      let billType = params.billType;
      params.platform = null;
      params.inOrOut = null;

      if (billType == '收入' || billType == '支出') {
        params.inOrOut = billType;
      } else if (billType != '合计') {
        let split = billType.split('-');
        params.platform = split[0];
        params.inOrOut = split[1];
      }


      //保存最后日期
      if (params.type == 'daily') {
        this.dailyParams = params;
        if (this.active == '日账单') {

          this.onRefresh();
        }
      } else if (params.type == 'month') {
        this.monthParams = params;
        if (this.active == '月账单') {

          this.onRefresh();
        }
      }

    }
  },
  'computed': {
    currentParams() {
      if (this.active === '日账单') {
        return this.dailyParams;
      } else if (this.active === '月账单') {
        return this.monthParams;
      }
      return {};
    },
    showDate() {
      if (this.active === '日账单') {
        return this.dailyParams.date;
      } else if (this.active === '月账单') {
        return this.monthParams.date;
      }
      return '';
    }
  }
};
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

}

.top-item {
  background-color: #f1f0f0;
}

.dates-title {
  font-weight: bold;
  font-size: 16px;
  color: #1989fa;
  border-color: #1989fa;
  padding: 0 16px;

}

.van-list {

  .van-cell {
    text-align: left;
  }
}
</style>
