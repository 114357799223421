<template>
  <div class="main-box">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive"></component>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive"></component>
    </router-view>
  </div>
</template>

<script>
export default {
  'name': 'App',
  'components': {}
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
}

.main-box {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0px;
}

:root {
  --van-tabs-nav-background-color: #f1f0f0;
  --van-tabs-bottom-bar-color: #1989fa;

}
</style>
