import axios from '@/requests';

function login(date) {
  return axios.post('/user/login', date);
}

function closeRemind(id, uuid) {
  return axios.get(`/remindItem/close/${id}?uuid=${uuid}`);
}

function getRemind(id) {
  return axios.get(`/remindItem/get/${id}`);
}

function pageRemind(page, size) {
  return axios.post(`/remindItem/page/${page}/${size}`);
}

function addRemind(remind) {
  return axios.post('/remindItem/add', remind);
}

function updateRemind(id, remind) {
  return axios.post(`/remindItem/update/${id}`, remind);
}

function delFlagRemind(id, flag) {
  return axios.post(`/remindItem/delFlag/${id}/${flag}`);
}
function delRemind(id) {
  return axios.post(`/remindItem/del/${id}`);
}

export {
  login,
  closeRemind,
  getRemind,
  pageRemind,
  addRemind,
  updateRemind,
  delRemind,
  delFlagRemind
};
