<template>
  <div>
    <van-nav-bar title="提醒事项" left-arrow @click-left="onClickLeft">

    </van-nav-bar>

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="form.name"
            name="名字"
            label="名字"
            placeholder="请输入名字"
            :rules="[{ required: true, message: '请填写名字' }]"
        />
        <van-field
            v-model="form.content"
            name="content"
            label="内容"
            placeholder="请输入内容"
            :rules="[{ required: false, message: '请填写内容' }]"
        />
        <van-field
            v-model="form.email"
            name="邮箱"
            type="email"
            label="邮箱"
            placeholder="请输入邮箱"
            :formatter="emailFormatter"
            :error-message="form.emailErrorText"
        />

        <van-field
            v-model="form.remindTime"
            name="提醒时间"
            label="提醒时间"
            placeholder="请输入提醒时间"
            autocomplete="off"
            @click="showDateTime"
            readonly
            :rules="[{required:true,message:'请填写提醒时间'}]"
        />
        <van-field
            v-model="form.remindType"
            name="提醒类型"
            label="提醒类型"
            @click="showRemindType"
            readonly
            autocomplete="off"
            placeholder="请输入提醒类型"
            :rules="[{required:true,message:'请填写提醒类型'}]"
        />
        <van-field
            v-model="form.state"
            name="状态"
            label="状态"
            autocomplete="off"
            placeholder="请输入状态"
            @click="showState"
            readonly
            :rules="[{required:true,message:'请填写提醒状态'}]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="show" position="bottom" @closed="popupClosed">
      <van-datetime-picker
          v-model="dateTime.dateTime"
          type="datetime"
          title="提醒时间"
          :formatter="dateTimeFormatter"
          v-show="dateTime.show"
          @change="dateTimeChange"
          @confirm="dateTimeConfirm"
          @cancel="dateTimeCancel"
      />
      <van-picker
          title="提醒类型"
          :columns="remindType.columns"
          v-show="remindType.show"
          v-model="form.remindType"
          :default-index="remindTypeDefaultIndex"
          @confirm="onRemindTypeConfirm"
          @cancel="onRemindTypeCancel"
          @change="onRemindTypeChange"
      />
      <van-picker
          title="状态"
          :columns="state.columns"
          v-show="state.show"
          v-model="form.state"
          :default-index="stateDefaultIndex"
          @confirm="onStateConfirm"
          @cancel="onStateCancel"
          @change="onStateChange"
      />
    </van-popup>

  </div>
</template>

<script>
import {formateDateTime, parseDateTime} from '../../utils/DateTimeUtil';
import {getDictionary} from '@/requests/dictionary';
import {updateRemind} from '@/requests/remindItem';
import {Toast} from 'vant';

export default {
  'name': 'RemindEditView',
  data() {
    return {
      'show': false,
      'create': null,
      'form': {
        'name': '',
        'content':'',
        'email': '',
        'remindTime': '',
        'remindType': '',
        'state': '',
        'emailErrorText': ''
      },
      'dateTime': {
        'show': false,
        'dateTime': new Date(),
        'dateTimeField': ''
      },
      'remindType': {
        'columns': [
          '1', '2', '3', '4'
        ],
        'show': false
      },
      'state': {
        'columns': ['1', '2'],
        'show': false
      }
    };
  },
  mounted() {

    getDictionary('REMIND_TYPE', (data) => this.remindType.columns = data);
    getDictionary('REMIND_STATE', (data) => this.state.columns = data);

    let remindItem = this.$route.params.remindItem;
    if (!remindItem) {
      this.$router.push('/remindList');
      return;
    }
    remindItem = JSON.parse(remindItem);

    this.form.name = remindItem.name;
    this.form.state = remindItem.state;
    this.form.remindTime = remindItem.remindTime;
    this.form.id = remindItem.id;
    this.form.email = remindItem.email;
    this.form.remindType = remindItem.remindType;
    this.form.content=remindItem.content;

  },
  'methods': {
    popupClosed() {
      this.state.show = false;
      this.remindType.show = false;
      this.dateTime.show = false;
    },
    showState() {
      this.show = true;
      this.state.show = true;
    },
    onStateConfirm() {
      this.state.show = false;
      this.show = false;
    },
    onStateCancel() {
      this.state.show = false;
      this.show = false;
    },
    onStateChange(val) {
      this.form.state = val;
    },
    onRemindTypeConfirm() {
      this.remindType.show = false;
      this.show = false;
    },
    onRemindTypeCancel() {
      this.remindType.show = false;
      this.show = false;
    },
    onRemindTypeChange(val) {
      this.form.remindType = val;
    },
    onSubmit() {

      updateRemind(this.form.id, this.form).then(res => {
        if (res.code === 200) {
          Toast.success('成功');
          this.$router.push('/remindList');
        } else {
          Toast.fail('res.message');
        }
      });
    },
    dateTimeConfirm() {
      this.dateTime.show = false;
      this.show = false;
    },
    dateTimeCancel() {
      this.dateTime.show = false;
      this.show = false;
    },
    showDateTime() {
      this.show = true;
      this.dateTime.show = true;
      this.dateTime.dateTimeField = 'remindTime';
      let date = new Date();
      if (this.form.remindTime) {
        date = parseDateTime(this.form.remindTime);
      } else {
        this.form.remindTime = formateDateTime(date);
      }
      this.dateTime.dateTime = date;


    },
    showRemindType() {
      this.show = true;
      this.remindType.show = true;
    },
    dateTimeChange(val) {

      let dateTime = formateDateTime(val);
      this.form.remindTime = dateTime;

    },
    dateTimeFormatter(type, val) {

      if (type === 'month') {
        return `${val}月`;
      }
      if (type === 'day') {
        return `${val}日`;
      }
      if (type === 'year') {
        return `${val}年`;
      }

      return val;
    },
    emailFormatter(val) {
      const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;

      if (!val) {
        return '';
      }
      // return reg.test(val);
      if (!reg.test(val)) {
        this.form.emailErrorText = '请输入正确的邮箱';
      } else {
        this.form.emailErrorText = '';
      }
      return val;
    },
    onClickLeft() {
      history.back();
    }
  },
  'computed': {
    remindTypeDefaultIndex() {

      for (const index in this.remindType.columns) {
        let item = this.remindType.columns[index];
        if (item == this.form.remindType) {
          return index;
        }
      }
      return 0;
    },
    stateDefaultIndex() {

      for (const index in this.state.columns) {
        let item = this.state.columns[index];
        if (item == this.form.state) {
          return index;
        }
      }
      return 0;
    }
  }
}
;
</script>

<style scoped>

</style>
