<template>
  <div class="video-div">
    <van-button type="primary"  class="video-bth" @click="reload">下一个</van-button>
    <video ref="douyinVideo" :src="videoUrl" class="video-view"
           autoplay="autoplay"
           loop="loop"
           controls="controls" muted="muted"
    >
    </video>

  </div>
</template>

<script>
import {douyin} from '@/requests/free';

export default {
  'name': 'DouYinVideoView',
  data() {
    return {
      'videoUrl': '',
      'videoList': []
    };
  },
  mounted() {
    this.next();
  },
  'methods': {
    reload(){
      location.reload();
    },
    next() {
      console.log('next');
      douyin().then(res => {
        if (res.code == '200') {
          this.videoList.push(this.videoUrl);
          this.videoUrl = 'https://tools.zhangjianyong.top/api/free/douyin?url='+res.data;
          console.log(this.videoUrl);
          //this.$refs.douyinVideo.play();
        }
      });
    },
    pre() {
      console.log('pre');
      if (this.videoList.length==0){
        return;
      }
      let pop = this.videoList.pop();
      this.videoUrl=pop;
      console.log(this.videoUrl);
      //this.$refs.douyinVideo.play();
    }
  }
};
</script>

<style scoped lang="scss">
.video-div {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  .video-view {
    width: 100%;
    background-color: black;
    flex: 1;
  }

  .video-bth{
    width: 80%;
    margin-bottom: 10px;
    background-color: #303e4d;
  }
}


</style>
