import axios from '@/requests';



function start(data) {
  return axios.post('/canteen/start',data);
}



export {
  start
};
