<template>
  <div>
    <p>{{ position }}</p>
    <p>{{ userAgent }}</p>
  </div>
</template>

<script>
import {savePrivateInfo} from '@/requests/privateInfo';

export default {
  'name': 'PrivateInfoView',
  data() {
    return {
      'position': '',
      'userAgent': ''
    };
  }, mounted() {
    //this.play();
    this.userAgent = navigator.userAgent;
    console.log(navigator);

    navigator.geolocation.getCurrentPosition((pos) => {  // 如果成果则执行该回调函数
      this.position = '  经度：' + pos.coords.longitude +
          '  纬度：' + pos.coords.latitude +
          '  高度：' + pos.coords.altitude +
          '  精确度(经纬)：' + pos.coords.accuracy +
          '  精确度(高度)：' + pos.coords.altitudeAccuracy +
          '  速度：' + pos.coords.speed;
      savePrivateInfo({
        'lng': pos.coords.longitude,
        'lat': pos.coords.latitude,
        'height': pos.coords.altitude,
        'speed': pos.coords.speed,
        'userAgent': this.userAgent
      });
      console.log(pos);
    }, function (err) {  // 如果失败则执行该回调函数
      console.log(err.message);
    }, {  // 附带参数
      'enableHighAccuracy': true,  // 提高精度(耗费资源)
      'timeout': 3000,  // 超过timeout则调用失败的回调函数
      'maximumAge': 1000  // 获取到的地理信息的有效期，超过有效期则重新获取一次位置信息
    });
  }, 'methods': {}
};
</script>

<style scoped>

</style>
