<template>

  <van-list>
    <van-card @click="open(item)" :style="getCardStyle(item)"
              v-for="(item,index) in videoList"
              :key="index"
              :title="item.title"
              :thumb="item.itemCover"
              :desc="item.hotWords"
              :price="item.author"
              currency=""
    />
  </van-list>

</template>

<script>
import {hotVideo, watchVideo} from '@/requests/free';

export default {
  'name': 'HotVideoView',
  data() {
    return {
      'active': '热门',
      'type': [
        '热门', '搞笑', '游戏', '美食', '汽车', '旅游', '体育', '二次元'
      ],
      'videoList': []
    };
  }, mounted() {
    hotVideo().then(res => {
      if (res.code == 200) {
        this.videoList = res.data;
      }
    });
  },
  'methods': {
    getCardStyle(item) {
      if (item.watchFlag == 0) {
        return 'background-color: white;';
      }
      return 'background-color: #eae9e9;color: #bbbbbb;';
    },
    open(item) {
      console.log(item.shareUrl);
      watchVideo(item.id).then(res => {
        if (res.code == 200) {
          item.watchFlag=1;
          window.location.href = item.shareUrl;
        }
      });

    }
  }
};
</script>

<style scoped lang="scss">
watched {
  background-color: #eae9e9;
  color: #bbbbbb;

}

no-watched {
  background-color: white;

}
</style>
