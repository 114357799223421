function formateDateTime(date) {
  let yyyy = date.getFullYear(),
    MM = date.getMonth()+1,
    dd = date.getDate(),
    HH = date.getHours(),
    mm = date.getMinutes(),
    ss = date.getSeconds();

  MM = MM < 10 ? '0' + MM : MM;
  dd = dd < 10 ? '0' + dd : dd;
  HH = HH < 10 ? '0' + HH : HH;
  mm = mm < 10 ? '0' + mm : mm;
  ss = ss < 10 ? '0' + ss : ss;
  return yyyy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm + ':' + ss;
}

function parseDateTime(str) {
  let split = str.split(' '),
    date = split[0],
    time = split[1],
    split1 = date.split('-'),
    yyyy = split1[0],
    MM = Number(split1[1])-1,
    dd = split1[2],

    split2 = time.split(':'),
    HH = split2[0],
    mm = split2[1],
    ss = split2[2];
  return new Date(yyyy, MM, dd, HH, mm, ss);
}

export {
  formateDateTime, parseDateTime
};
