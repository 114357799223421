<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="对账文件"
                   left-text="返回"
                   left-arrow
                   @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>
    <!--上传文件-->
    <van-row>
      <van-col span="24">
        <van-divider class="upload-title" content-position="left">上传文件</van-divider>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="1"/>
      <van-col span="15">
        <van-cell-group inset>
          <van-field name="解压密码" v-model="password" placeholder="请输入解压密码"></van-field>
        </van-cell-group>

      </van-col>

      <van-col span="8">
        <van-uploader :before-read="beforeRead" :after-read="afterRead" accept=".zip,.csv">
          <van-button icon="plus" type="primary">上传文件</van-button>
        </van-uploader>
      </van-col>

    </van-row>


    <van-row>
      <van-col span="24">
        <van-divider class="dates-title" content-position="left">已导入账期</van-divider>
      </van-col>
    </van-row>
    <span v-if="!wxDates||wxDates.length==0"> 微信：没有找到账单;</span>
    <van-row v-for="(item,index) in wxDates" :key="index">
      <van-col span="2"/>
      <van-col span="22" style="text-align: left">
        <span class="dates-value"> 微信：{{ item.start }} ~ {{ item.end }};</span>
      </van-col>
    </van-row>

    <span v-if="!aliDates||aliDates.length==0"> 支付宝：没有找到账单;</span>
    <van-row v-for="(item,index) in aliDates" :key="index">
      <van-col span="2"/>
      <van-col span="22" style="text-align: left">
        <span class="dates-value"> 支付宝：{{ item.start }} ~ {{ item.end }};</span>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <van-divider class="dates-title" content-position="left">账单文件列表</van-divider>
      </van-col>
    </van-row>

    <van-list>
      <van-swipe-cell style="margin: 2px 0" v-for="(item,index) in billList" :key="index">

        <van-cell class="cell"  :value="item.state">
          <template #title>
            <span class="custom-title">{{ item.name }}</span>
          </template>
        </van-cell>

        <template #right>
          <van-button square type="danger" class="cell-btn" text="删除" @click="del(item)"/>
          <van-button square type="primary" class="cell-btn" text="导入" @click="read(item)"/>
        </template>
      </van-swipe-cell>

    </van-list>

  </div>

</template>

<script>


import {billDates, billList,billDel,readFile, uploadBill} from '@/requests/billFile';
import {Toast} from 'vant';

export default {
  'name': 'BillFileView',
  data() {
    return {
      'wxDates': [],
      'aliDates': [],
      'password': '',
      'billList': []
    };
  },
  mounted() {
    billDates().then(res => {
      if (res.code == 200) {
        this.wxDates = res.data['微信'];
        this.aliDates = res.data['支付宝'];
      } else {
        Toast.fail(res.message);
      }
    });
    this.reloadBillList();
  },
  'methods': {
    reloadBillList(){
      billList().then(res => {
        if (res.code == 200) {
          this.billList = res.data;
        } else {
          Toast.fail(res.message);
        }
      });
    },
    del(item){
      billDel(item.id).then(res=>{
        if (res.code==200){
          Toast.success(res.message);
          this.reloadBillList();
        }
      });
    },
    read(item){
      readFile(item.id).then(res=>{
        if (res.code==200){
          Toast.success(res.message);
          this.reloadBillList();
        }
      });
    },
    onClickLeft() {
      this.$router.push('/main');
    },
    afterRead(info) {
      uploadBill(this.password, info.file).then(res => {
        if (res.code == 200) {
          Toast.success('成功');
        } else {
          Toast.fail(res.message);

        }
      });
    },
    beforeRead() {
      if (!this.password || this.password == '') {
        Toast.fail('请输入解压密码');
        return false;
      }
      return true;
    }
  },
  'computed': {}
}
;
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

}

.dates-title {
  font-weight: bold;
  font-size: 16px;
  color: #1989fa;
  border-color: #1989fa;
}

.upload-title {
  font-weight: bold;
  font-size: 16px;
  color: rgba(28, 46, 59, 0.88);
  border-color: rgba(28, 46, 59, 0.88);
  padding: 0 16px;

}

.dates-value {

  text-align: left;
  color: rgba(27, 41, 47, 0.90);
  font-weight: 200;
  font-size: 16px;
}
.cell{
  min-height: 70px;
}
.cell-btn{
  height: 100%;
}
</style>
