import axios from '@/requests';

function median(unit, num) {
  return axios.post(`/etf/median/${unit}/${num}`);
}

function setHoldFlag(code, flag) {
  return axios.post(`/etf/hold/${code}/${flag}`);
}


export {
  median, setHoldFlag
};
