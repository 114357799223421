<template>
  <div style="display: flex;height:100%;flex-direction: column">

    <!--查询垃圾-->
    <van-row style="margin-top: 30px;">
      <van-col span="1"></van-col>
      <van-col span="16">
        <van-field name="垃圾名称" label="垃圾名称" v-model="name" placeholder="请输入垃圾名称"></van-field>
      </van-col>
      <van-col span="6">
        <van-button type="primary" style="width: 100%" @click="search">查询</van-button>
      </van-col>
      <van-col span="1"></van-col>
    </van-row>
    <!--查询结果展示-->
    <van-row>
      <van-col span="2"></van-col>
      <van-col span="22" style="text-align: left">
        <span v-if="type">查询结果: {{ showName }} - {{ type }}</span>
      </van-col>
    </van-row>
    <!--分割线-->
    <van-divider content-position="left" class="search-title">
    </van-divider>
    <!--垃圾列表-->
    <div style="flex: 1;">
      <van-list>
        <van-cell v-for="(item,index) in lajiList"
                  :key="index"
                  :title="item.name"
                  title-style="text-align: left"
                  :value="item.type">
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>

import {Toast} from 'vant';
import {laji, lajiHis} from '@/requests/free';

export default {
  'name': 'LaJiView',
  data() {
    return {
      'name': '',
      'showName': '',
      'type': '',
      'lajiList': []
    };
  },
  mounted() {
    this.reload();
  },
  'methods': {
    reload() {
      lajiHis().then(res => {
        if (res.code == 200) {
          this.lajiList = res.data;
        } else {
          Toast.fail(res.message);
        }
      });
    },
    search() {
      laji(this.name).then(res => {
        if (res.code == 200) {
          this.showName = this.name;
          this.name = '';
          this.type = res.data;
          this.reload();
        } else {
          Toast.fail(res.message);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">

.search-title {
  font-weight: bold;
  font-size: 16px;
  color: rgba(28, 46, 59, 0.88);
  border-color: rgba(28, 46, 59, 0.88);
  padding: 0 16px;

}
</style>
