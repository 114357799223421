import axios from '@/requests';

function dailyBill() {
  return axios.post('/bill/daily');
}

function monthBill() {
  return axios.post('/bill/month');
}

function dailyRank(date, platform, inOrOut, page, size) {
  let formDate = new FormData();
  formDate.append('date', date);
  if (platform !== null) {
    formDate.append('platform', platform);
  }
  if (inOrOut !== null) {
    formDate.append('inOrOut', inOrOut);
  }
  return axios.post(`/bill/rank/daily/${page}/${size}`, formDate);
}

function monthRank(month, platform, inOrOut, page, size) {
  let formDate = new FormData();
  formDate.append('month', month);
  if (platform !== null) {
    formDate.append('platform', platform);
  }
  if (inOrOut !== null) {
    formDate.append('inOrOut', inOrOut);
  }
  return axios.post(`/bill/rank/month/${page}/${size}`, formDate);
}

function rank(type, date, platform, inOrOut, page, size) {
  if (type == 'daily') {
    return dailyRank(date, platform, inOrOut, page, size);
  } else if (type == 'month') {
    return monthRank(date, platform, inOrOut, page, size);
  }
}

export {
  dailyBill,
  monthBill,
  rank
};
