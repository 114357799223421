import axios from '@/requests';
import {Toast} from 'vant';

function dictionaryList(key) {
  return axios.post(`/dictionary/list/${key}`);
}

function getDictionary(key, cb) {
  dictionaryList(key).then(res => {
    if (res.code === 200) {
      let data = [];
      for (const item of res.data) {
        data.push(item.dictValue);
      }
      cb(data);
    } else {
      Toast.fail(res.message);
    }
  }).catch(e => {
    Toast.fail(e.message);
  });
}

export {
  dictionaryList, getDictionary
};
