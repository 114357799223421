import axios from '@/requests';

const headers = {
  'Content-Type': 'multipart/form-data'
};

function torrentToMagnet(file) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/torrent/magnet', formData, headers);
}

function torrentList() {
  return axios.post('/torrent/list');
}


export {
  torrentToMagnet,
  torrentList
};
